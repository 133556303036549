import { Options, Vue } from "vue-class-component";
import { IEmployeeNewEdit } from "@/types/Employee";
import { IDepartment } from "@/types/Department";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { IGender } from "@/types/Employee";
import { IAddress } from "@/types/Address";
import { ICountry } from "@/types/Address";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import { ILeave } from "@/types/Leave";
import Breadcrumb from "@/components/Breadcrumb.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { notify } from "@/services/helpers";

@Options({
  name: "NewEmployee",
  components: {
    Form,
    Field,
    ErrorMessage,
    Breadcrumb,
    Datepicker,
    Dropdown: DropdownComponent,
  },
  data: function () {
    return {
      schema: yup.object().shape({
        firstName: yup
          .string()
          .required("Please provide your first name.")
          .max(75, "Must not exceed 75 characters in length."),
        lastName: yup
          .string()
          .required("Please provide your last name.")
          .max(75, "Must not exceed 75 characters in length."),
        maritalStatus: yup
          .string()
          .max(20, "Must not exceed 20 characters in length."),
        mobileNumber: yup
          .string()

          .required("Please enter your mobile number.")
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        officeNumber: yup
          .string()
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        homeNumber: yup
          .string()
          .required("Please provide your home number.")
          .test(
            "minLength",
            "Must be at least 7 characters in length.",
            function (value) {
              return !value || value.length >= 7;
            }
          )
          .max(14, "Must not exceed 14 characters in length."),
        workEmail: yup
          .string()
          .required("Please provide your work email")
          .email("Please provide a valid email address."),
        personalEmail: yup
          .string()
          .email("Please provide a valid email address."),
        departmentId: yup
          .number()
          .moreThan(0, "Please select a department from the list.")
          .required("Please select a department from the list."),
        jobTitle: yup
          .string()
          .required("Please provide your job title.")
          .max(50, "Must not exceed 50 characters in length."),
        medicalConditions: yup
          .string()
          .max(200, "Must not exceed 200 characters in length."),
        line1: yup
          .string()
          .required("Please provide the first line of your address (Line 1).")
          .max(200, "Must not exceed 200 characters in length."),
        line2: yup
          .string()
          .max(200, "Must not exceed 200 characters in length."),
        town: yup
          .string()
          .required("Please provide the name of your town.")
          .max(100, "Must not exceed 100 characters in length."),
        city: yup
          .string()
          .required("Please provide the name of your city.")
          .max(50, "Must not exceed 50 characters in length."),
        countryId: yup
          .number()
          .moreThan(0, "Please select a country from the list.")
          .required("Please select a country from the list.")
          .max(15, "Must not exceed 15 characters in length."),
        postCode: yup
          .string()
          .required("Please provide your postal code.")
          .max(50, "Must not exceed 50 characters in length."),
        leavesQuota: yup
          .number()
          .min(
            0,
            "Please make sure that the input value is greater than or equal to 0."
          )
          .max(50, "Please ensure that the input value does not exceed 50."),
        carryOverDays: yup
          .number()
          .min(
            0,
            "Please make sure that the input value is greater than or equal to 0."
          )
          .max(50, "Please ensure that the input value does not exceed 50."),
        dob: yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years."
          )
          .required("Please provide date of birth (DOB)."),

        jobCommencementDate: yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .required("Please provide the job commenced date."),
      }),
    };
  },
  props: {},
})
export default class NewEmployee extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  departments: IDepartment[] = [];
  countries: ICountry[] = [];
  gender: IGender[] = [
    { id: 0, value: "Male" },
    { id: 1, value: "Female" },
    { id: 2, value: "Prefer not to say" },
  ];
  crumbs: any = ["Dashboard", "Employees", "New Employee"];
  fields: any = { text: "name", value: "id" };
  address: IAddress = {
    id: 0,
    line1: "",
    line2: "",
    town: "",
    city: "",
    postCode: "",
    countryId: 0,
  };

  async goToRoute(route: string): Promise<any> {
    await this.$router.push(route);
  }

  message = "";

  newEmployee: IEmployeeNewEdit = {
    id: 0,
    firstName: "",
    lastName: "",
    gender: "",
    homeNumber: "",
    maritalStatus: "",
    medicalConditions: "",
    mobileNumber: "",
    smsNotification: false,
    officeNumber: "",
    personalEmail: "",
    workEmail: "",
    jobTitle: "",
    departmentId: 0,
    department: "",
    isActive: false,
    departmentName: "",
    manager: "",
    managerId: 0,
    addressId: 0,
    leavesQuota: 0,
    carryOverDays: 0,
    dob: "",
    jobCommencementDate: "",
    role: "",
  };
  loading = false;

  formatDate(date: any) {
    const nDate = new Date(date);
    const year = nDate.getFullYear();
    const month = nDate.getMonth() + 1;
    const day = nDate.getDate();
    return (
      year +
      "-" +
      (month.toString().length < 2 ? "0" + month : month) +
      "-" +
      (day.toString().length < 2 ? "0" + day : day)
    );
  }

  calculateAge(date: Date) {
    const today = new Date();
    const birthDate = new Date(date);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  async save(): Promise<any> {
    this.loading = true;
    this.message = "";
    if (
      new Date(this.newEmployee.dob) >
      new Date(this.newEmployee.jobCommencementDate)
    ) {
      notify(
        "Please ensure that the date of birth (DOB) is earlier than the job commencement date.",
        "Attention",
        "danger"
      );
      this.loading = false;
      return;
    } else if (this.calculateAge(new Date(this.newEmployee.dob)) < 18) {
      notify(
        "Please ensure that the date of birth (DOB) age is equal to or greater than 18 years.",
        "Attention",
        "danger"
      );
      this.loading = false;
      return;
    }
    this.newEmployee.carryOverDays = parseInt(
      this.newEmployee.carryOverDays.toString()
    );
    this.newEmployee.leavesQuota = parseInt(
      this.newEmployee.leavesQuota.toString()
    );

    this.newEmployee.gender = this.newEmployee.gender.toString();

    this.newEmployee.dob = this.formatDate(this.newEmployee.dob);
    this.newEmployee.jobCommencementDate = this.formatDate(
      this.newEmployee.jobCommencementDate
    );

    this.workForceService
      .postOrPut<void>(`/address/`, this.address as IAddress, undefined)
      .then((response: any) => {
        if (!response.isError) {
          this.newEmployee.addressId = response.content.id;
          this.message = "";
          this.workForceService
            .postOrPut<void>(
              "/employee",
              this.newEmployee as IEmployeeNewEdit,
              undefined
            )
            .then((response: any) => {
              this.loading = true;
              if (!response.isError) {
                this.loading = false;
                notify("Employee added successfully.", "Success", "success");
                this.goToRoute("/admin/employee");
              } else {
                this.loading = false;
                notify(response.errors, "Error", "danger");
              }
            });
        } else {
          this.loading = false;
          notify(response.errors, "Error", "danger");
        }
      });
  }

  async initialize(): Promise<any> {
    await this.workForceService
      .get<IDepartment[]>(
        `/departments?IsActive=true&PageIndex=0&PageSize=100`,
        false
      )
      .then((response: any) => {
        this.departments = response.content.items;
      });

    await this.workForceService
      .get<ICountry[]>(`/countries`, false)
      .then((response: any) => {
        this.countries = response.content;
      });

    await this.workForceService
      .get<ILeave[]>(`/settings/LeavesQuota`, false)
      .then((response: any) => {
        this.newEmployee.leavesQuota = parseInt(response.content.value);
      });

    await this.workForceService
      .get<ILeave[]>(`/settings/CarryOverDays`, false)
      .then((response: any) => {
        this.newEmployee.carryOverDays = parseInt(response.content.value);
      });
  }

  async created(): Promise<any> {
    await this.initialize();
  }
}
