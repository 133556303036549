<template>
  <!--  Breadcrumbs-->
  <Breadcrumb :crumbs="crumbs" />

  <!--  Content-->
  <div class="custom-container">
    <!--    Page Title-->
    <page-title>
      <template #title>
        <span class="h4 text-primary">New Employee</span>
      </template>
      <template #button>
        <span>
          <button class="btn btn-secondary" @click="this.$router.back()">
            <font-awesome-icon class="mr-1" icon="arrow-left" />
            Go Back
          </button>
        </span>
      </template>
    </page-title>

    <Form @submit="save" v-slot="meta" :validation-schema="schema">
      <!--            Row 1: First Name & Last Name-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">First name</label>
          <input-field icon="id-card">
            <template v-slot:input>
              <Field
                type="text"
                name="firstName"
                placeholder="First Name"
                id="firstName"
                :class="meta.errors.firstName ? 'is-invalid' : ''"
                v-model="newEmployee.firstName"
              />
              <ErrorMessage name="firstName" class="invalid-feedback" />
            </template>
          </input-field>
        </div>

        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Last name</label>
          <input-field icon="id-card">
            <template v-slot:input>
              <Field
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                :class="meta.errors.lastName ? 'is-invalid' : ''"
                v-model="newEmployee.lastName"
              />
              <ErrorMessage name="lastName" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 2: Gender &  Home Number-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label>Gender</label>

          <Dropdown
            size="lg"
            name="genderId"
            :options="gender"
            v-model="newEmployee.gender"
            placeholder="Please select gender (optional)"
            icon="venus-mars"
            :fields="['id', 'value']"
            clearable
          />
        </div>

        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Home number</label>

          <input-field icon="home">
            <template v-slot:input>
              <Field
                type="text"
                id="homeNumber"
                name="homeNumber"
                placeholder="Home Number"
                :class="meta.errors.homeNumber ? 'is-invalid' : ''"
                v-model="newEmployee.homeNumber"
              />
              <ErrorMessage name="homeNumber" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 3: Mobile Number & Office Number-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required mb-0">Mobile number</label>
          <small class="d-block text-muted mb-2"
            >(Please enter full number including country code without trailing
            00 or + to ensure SMS delivery. e.g. 353861234567)</small
          >
          <input-field icon="phone-alt">
            <template v-slot:input>
              <Field
                type="text"
                name="mobileNumber"
                id="mobileNumber"
                placeholder="Mobile Number"
                :class="meta.errors.mobileNumber ? 'is-invalid' : ''"
                v-model="newEmployee.mobileNumber"
              />
              <ErrorMessage name="mobileNumber" class="invalid-feedback" />
            </template>
          </input-field>
          <div v-if="false" class="d-inline-block mt-2">
            <label
              :class="`cursor-pointer rounded-sm m-0 px-2 py-1 border ${
                newEmployee.smsNotification ? 'border-primary' : ''
              }`"
              for="flexCheckDefault"
            >
              <input
                type="checkbox"
                v-model="newEmployee.smsNotification"
                id="flexCheckDefault"
              />
              Receive SMS Notifications
            </label>
          </div>
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label>Office number</label>
          <input-field icon="phone-alt">
            <template v-slot:input>
              <Field
                type="text"
                id="officeNumber"
                name="officeNumber"
                placeholder="Office Number (optional)"
                :class="meta.errors.officeNumber ? 'is-invalid' : ''"
                v-model="newEmployee.officeNumber"
              />
              <ErrorMessage name="officeNumber" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 5 emails-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Work email</label>
          <input-field icon="at">
            <template v-slot:input>
              <Field
                type="text"
                id="workEmail"
                name="workEmail"
                placeholder="Work Email"
                :class="meta.errors.workEmail ? 'is-invalid' : ''"
                v-model="newEmployee.workEmail"
              />
              <ErrorMessage name="workEmail" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label>Personal email</label>
          <input-field icon="at">
            <template v-slot:input>
              <Field
                type="text"
                id="personalEmail"
                name="personalEmail"
                v-model="newEmployee.personalEmail"
                placeholder="Personal Email (optional)"
                :class="meta.errors.personalEmail ? 'is-invalid' : ''"
              />
              <ErrorMessage name="personalEmail" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 6 Department | Job title -->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Department</label>
          <Dropdown
            size="lg"
            :options="departments"
            name="departmentId"
            :input-class="meta.errors.departmentId ? 'is-invalid' : ''"
            placeholder="Select a department"
            icon="building"
            v-model="newEmployee.departmentId"
            :fields="['id', 'name']"
            filterable
          />
          <!--            Extra Field - hidden for validation-->
          <Field
            name="departmentId"
            id="departmentId"
            :class="meta.errors.departmentId ? 'is-invalid' : ''"
            type="hidden"
            v-model="newEmployee.departmentId"
          />
          <ErrorMessage name="departmentId" class="invalid-feedback" />
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Job Title</label>
          <input-field icon="id-card">
            <template v-slot:input>
              <Field
                type="text"
                id="jobTitle"
                name="jobTitle"
                placeholder="Job Title"
                :class="meta.errors.jobTitle ? 'is-invalid' : ''"
                v-model="newEmployee.jobTitle"
              />
              <ErrorMessage name="jobTitle" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 7 Date-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Job commencement date</label>
          <Datepicker
            uid="jobDate"
            :inputClassName="
              meta.errors.jobCommencementDate
                ? 'dp-lg shadow-sm dp-invalid'
                : 'dp-lg shadow-sm'
            "
            placeholder="Please select a date"
            calendarCellClassName="dp-round-cell"
            menuClassName="dp-menu"
            autoApply
            v-model="newEmployee.jobCommencementDate"
            format="dd/MM/yyyy"
            clearable
            :enableTimePicker="false"
          >
            <template #input-icon>
              <span class="pl-3">
                <font-awesome-icon class="text-primary" icon="calendar-check" />
              </span>
            </template>
          </Datepicker>
          <Field
            type="hidden"
            id="jobCommencementDate"
            name="jobCommencementDate"
            :class="meta.errors.jobCommencementDate ? 'is-invalid' : ''"
            v-model="newEmployee.jobCommencementDate"
          />
          <ErrorMessage name="jobCommencementDate" class="invalid-feedback" />
        </div>

        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Date of Birth</label>
          <Datepicker
            uid="dob"
            placeholder="Please select a date"
            :inputClassName="
              meta.errors.dob ? 'dp-lg shadow-sm dp-invalid' : 'dp-lg shadow-sm'
            "
            calendarCellClassName="dp-round-cell"
            menuClassName="dp-menu"
            autoApply
            v-model="newEmployee.dob"
            format="dd/MM/yyyy"
            clearable
            :enableTimePicker="false"
          >
            <template #input-icon>
              <span class="pl-3">
                <font-awesome-icon class="text-primary" icon="calendar-check" />
              </span>
            </template>
          </Datepicker>

          <Field
            type="hidden"
            id="dob"
            name="dob"
            :class="meta.errors.dob ? 'is-invalid' : ''"
            v-model="newEmployee.dob"
          />
          <ErrorMessage name="dob" class="invalid-feedback" />
        </div>
      </div>

      <!--            Row 8-->
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Leaves Quota</label>
          <input-field icon="calendar-check">
            <template v-slot:input>
              <Field
                type="number"
                name="leavesQuota"
                disabled
                placeholder="Leave Quota"
                :class="meta.errors.leavesQuota ? 'is-invalid' : ''"
                v-model="newEmployee.leavesQuota"
              />
              <ErrorMessage name="leavesQuota" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Carry Over Days</label>
          <input-field icon="calendar-check">
            <template v-slot:input>
              <Field
                type="number"
                name="carryOverDays"
                placeholder="Carry Days"
                :class="meta.errors.carryOverDays ? 'is-invalid' : ''"
                v-model="newEmployee.carryOverDays"
              />
              <ErrorMessage name="carryOverDays" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <!--            Row 9-->
      <div class="row mb-4">
        <div class="col-12 form-group">
          <label>Medical Conditions</label>
          <textarea
            name="medicalConditions"
            id="medicalConditions"
            placeholder="Medical condition (optional)"
            v-model="newEmployee.medicalConditions"
            rows="5"
            :class="
              meta.errors.medicalConditions
                ? 'shadow-sm form-control is-invalid'
                : 'shadow-sm form-control'
            "
          ></textarea>
          <Field
            name="medicalConditions"
            :class="meta.errors.medicalConditions ? 'is-invalid' : ''"
            type="hidden"
            v-model="newEmployee.medicalConditions"
          />
          <ErrorMessage name="medicalConditions" class="invalid-feedback" />
        </div>
      </div>

      <!--            Address Part-->

      <!--            Row 10-->
      <div class="row">
        <div
          class="col-xs-12 col-sm-10 col-lg-10 col-xl-8 col-md-12 form-group"
        >
          <div class="h5 border-bottom pb-2">Address</div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Line 1</label>
          <input-field icon="address-book">
            <template v-slot:input>
              <Field
                type="text"
                name="line1"
                placeholder="Line 1"
                :class="meta.errors.line1 ? 'is-invalid' : ''"
                v-model="address.line1"
              />
              <ErrorMessage name="line1" class="invalid-feedback" />
            </template>
          </input-field>
        </div>

        <div class="col-xs-12 col-md-6 form-group">
          <label>Line 2</label>
          <input-field icon="address-book">
            <template v-slot:input>
              <Field
                type="text"
                name="line2"
                placeholder="Line 2 (optional)"
                :class="meta.errors.line2 ? 'is-invalid' : ''"
                v-model="address.line2"
              />
              <ErrorMessage name="line2" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Town</label>
          <input-field icon="address-book">
            <template v-slot:input>
              <Field
                type="text"
                name="town"
                placeholder="Town"
                :class="meta.errors.town ? 'is-invalid' : ''"
                v-model="address.town"
              />
              <ErrorMessage name="town" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">City</label>
          <input-field icon="building">
            <template v-slot:input>
              <Field
                type="text"
                name="city"
                placeholder="City"
                :class="meta.errors.city ? 'is-invalid' : ''"
                v-model="address.city"
              />
              <ErrorMessage name="city" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Country</label>
          <Dropdown
            size="lg"
            :options="countries"
            name="countryId"
            :input-class="meta.errors.countryId ? 'is-invalid' : ''"
            placeholder="Select a Country"
            icon="globe"
            v-model="address.countryId"
            :fields="['id', 'name']"
            filterable
          />
          <!--            Extra Field - hidden for validation-->
          <Field
            name="countryId"
            id="countryId"
            :class="meta.errors.countryId ? 'is-invalid' : ''"
            type="hidden"
            v-model="address.countryId"
          />
          <ErrorMessage name="countryId" class="invalid-feedback" />
        </div>
        <div class="col-xs-12 col-md-6 form-group">
          <label class="required">Post Code</label>
          <input-field icon="map-pin">
            <template v-slot:input>
              <Field
                type="text"
                id="postCode"
                name="postCode"
                placeholder="Post Code"
                :class="meta.errors.postCode ? 'is-invalid' : ''"
                v-model="address.postCode"
              />
              <ErrorMessage name="postCode" class="invalid-feedback" />
            </template>
          </input-field>
        </div>
      </div>

      <div class="row mb-5 mt-3 justify-content-center">
        <div class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group">
          <button
            :isPrimary="true"
            type="button"
            class="btn-secondary btn-lg-cus"
            @click="this.$router.back()"
          >
            Cancel
          </button>
        </div>

        <div class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 col-md-5 form-group">
          <button
            class="btn-primary btn-lg-cus"
            type="submit"
            :disabled="loading"
          >
            <span>Save </span>
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div
            v-if="message"
            v-html="message"
            class="alert alert-danger small"
          ></div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script src="./new-employee.ts"></script>

<style scoped></style>
